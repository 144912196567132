/* =========================
Custom Input range 
=============================*/

/* Input range - chrome and safari */

input[type="range"] {
  --range-progress: 0;
  
  -webkit-appearance: none;
  position: relative;
  background:rgb(177, 177, 177);
  width: 100%;
  height: 2rem;
  border-radius: 2px;
  cursor: pointer;
}

/* Input range - firefox */
input[type="range"]::-moz-range-track {
  position: relative;
  background:#ccc;
  width: 100%;
  height: 0.5rem;
  border-radius: 2px;
  cursor: pointer;
}

/* played progress length - Chrome & safari*/
input[type="range"]::before {
  content: '';
  height: 0.5rem;
  background:rgb(255, 131, 69);
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0.75rem;
  left: 0;
}

/* played progress length - firefox */
input[type="range"]::-moz-range-progress {
  background: #f50;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 2px;
}

/* slider thumb - chrome and safari */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 50%;
  border: none;
  background-color: #f50;
  cursor: pointer;
  position: relative;
}

/* dragging thumb - chrome and safari */
input[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

/* slider thumb - firefox */
input[type="range"]::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #f50;
  cursor: pointer;
  border: transparent;
  position: relative;
}
/* dragging thumb - firefox */
input[type="range"]:active::-moz-range-thumb {
  transform: scale(1.2);
}


/* Speed Range Slider Style */
.speed-range {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  margin: 0;
  height: auto !important;
  position: relative;
  z-index: 2;
}

.speed-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px !important;
  background: var(--bs-primary) !important;
  border: none;
  border-radius: 1px;
  position: relative;
  z-index: 2;
}

.speed-range::-moz-range-track {
  width: 100%;
  height: 2px !important;
  background: var(--bs-primary) !important;
  border: none;
  border-radius: 1px;
  position: relative;
  z-index: 2;
}

.speed-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px !important;
  width: 16px !important;
  border-radius: 50%;
  background: #ff8c00 !important;
  cursor: pointer;
  margin-top: -7px;
  box-shadow: 0 0 4px rgba(0,0,0,0.2);
  transform: scale(1) !important;
  position: relative;
  z-index: 3;
}

.speed-range::-moz-range-thumb {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50%;
  background: #ff8c00 !important;
  cursor: pointer;
  border: none;
  box-shadow: 0 0 4px rgba(0,0,0,0.2);
  transform: scale(1) !important;
  position: relative;
  z-index: 3;
}

/* Entfernen des "before" Pseudo-Elements für diesen speziellen Slider */
.speed-range::before {
  display: none;
}

.speed-range-container {
  position: relative;
}

.speed-range-container::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 10px;
  background-color: rgba(0,0,0,0.2);
  pointer-events: none;
  z-index: 1;
}