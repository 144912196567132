* {
  box-sizing: border-box;
}
@import "leaflet/dist/leaflet.css";

body {
  font-family: sans-serif;
  background-color: rgba(var(--bs-light-rgb));
  /*background-color: black;*/
  margin: 0 10px;
  touch-action: none; /* Verhindert das Ziehen auf Touch-Geräten */
}

.content {
  touch-action: none;
}

.audio-player {
  background: #c1b6bc;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}


.block {
  display: block;
}
.flex {
  display: flex;
 
}
.jcenter{
  justify-content: center; /* Zentriert die Inhalte horizontal */
  ;
}

.center{
  align-items: center;
  text-align: center;
}
/* audio info */
.audio-info {
  display: block;
}

.text {
  color: rgb(255, 255, 255);
}

.text-red {
  color: rgb(200, 22, 22);
}



.project-title-psmall {
  color: #000000;
  font-weight: 100;
  font-size: 1.5rem;
  padding: 0.5rem;
  line-height: 1.25rem;
  text-align: left;
}

.tarck-title-psmall {
  height: 100%;
  color: #ce2121;
  font-weight: 100;
  font-size: 1rem;
  text-align: center;
}


.track-description{
  margin-top: 0.5rem;
  color: #000000;
  font-weight: 100;
  font-size: 1rem;
  text-align: left;
}
.audio-image-psmall{
  height: 4rem;
  max-width: 25rem;
  min-width: 1rem;
  background: #1e1e1e;
  display: block;

}

.cover-wrapper{
  align-items: center;
  text-align: center;
}


.audio-icon {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #989898;
}



.controls button, .volume button {
  border: none;
  background-color: transparent;
  margin-right: 8px;
  cursor: pointer;
}

.controls-pbig {
  display: flex; 
  justify-content: center;
  align-items: center; 
  gap: 2rem;

}

.controls-pbig button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

button svg {
  font-size: 20px;
}

.volume {
  display: flex;
  align-items: center;
}

.volume button {
  margin: 0;
}



/* time */
.time {
  color: #ffffff;
  font-size: 0.9rem;
  line-height: 0.9rem;
  min-width: 50px; /* Stellt sicher, dass die Zeitangaben nicht zusammendrücken */
  text-align: center;
}


.time.current {
  color: rgb(255, 255, 255);
}

.time-small {
  color: #000000;
  font-size: 0.9rem;
  line-height: 0.9rem;
  min-width: 50px; /* Stellt sicher, dass die Zeitangaben nicht zusammendrücken */
  text-align: center;
}


.time-small.current {
  color: rgb(255, 0, 0);
}

/* Top bar */
.top__bar {
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f2;
}

.top__bar a {
  color: inherit;
}

.top__bar p {
  margin: 0;
}


.audio-player-container-small {
  display: inline;
  align-items: center;
  width: 100%;
  position: fixed; /* Nutze `fixed` statt `absolute`, um den Player immer sichtbar zu machen */
  left: 0;
  bottom: 3.3rem; /* Stelle sicher, dass dies der Höhe der Navigationsleiste entspricht */
  z-index: 1000; /* Stelle sicher, dass der Player über der Navigationsleiste ist */
  background-color: rgba(var(--bs-light-rgb));
}


.player-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.track-thumbnail {
  flex-shrink: 0;
  width: 3rem;
  max-height: 3.5rem;
  margin-right: 1rem;
}

.playlist-thumbnail-container {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  overflow: hidden; /* Um sicherzustellen, dass das Bild nur innerhalb des Containers sichtbar ist */
  display: inline-block; /* Damit der Container und das Bild inline miteinander funktionieren */
}

.playlist-thumbnail {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}



.map-icon-container {
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  overflow: hidden; /* Um sicherzustellen, dass das Bild nur innerhalb des Containers sichtbar ist */
  display: inline-block; /* Damit der Container und das Bild inline miteinander funktionieren */
}

.map-icon {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  border-radius: 20%;
}

.custom-icon {
  width: 100%;
  height: 100%;
  border-radius: 20%;
  object-fit: cover;
  object-position: center;
}

.selected-icon {
  border-radius: 20%;

}

.audio-image-small {
  width: 100%;
  height: auto;
  border-radius: 10%;
}

.controls-small {
  margin-right: 1.5rem;
}

.track-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.track-title-small {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
}

@media (max-width: 722px) {
  .track-title-small {
    margin-bottom: 0.0rem;
  }
}

.progress {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-width: 10rem;
  height: 2rem;
  justify-content: space-between;
  background-color: transparent; /* Hintergrundfarbe entfernen */
}

@media (max-width: 722px) {
  .progress-small {
    display: none;
  }
}

.progress-container {
  width: 90%;
  display: flex;
  flex-direction: column;
}

input[type="range"].progress-bar {
  --range-progress: 0;
  -webkit-appearance: none;
  position: relative;
  background: linear-gradient(to right,  #0008a3, var(--range-progress), #e0e0e0 0%) !important;
  width: 100% !important;
  height: 0.3rem !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  margin-bottom: 0.25rem;
}

input[type="range"].progress-bar::-webkit-slider-runnable-track {
  height: 0.3rem;
  background: transparent;
}

input[type="range"].progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -0.35rem; /* Adjusts the thumb's vertical alignment */
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: orange;
  cursor: pointer;
  position: relative;
  z-index: 3;
}

input[type="range"].progress-bar::-moz-range-track {
  height: 0.3rem;
  background: transparent;
}

input[type="range"].progress-bar::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: orange;
  cursor: pointer;
  position: relative;
  z-index: 3;
  transform: translateY(-50%);
}

input[type="range"].progress-bar::-ms-track {
  height: 0.3rem;
  background: transparent;
  border: none;
  color: transparent;
}

input[type="range"].progress-bar::-ms-thumb {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: orange;
  cursor: pointer;
  position: relative;
  z-index: ;
  transform: translateY(-50%);
}

input[type="range"].progress-bar::-ms-fill-lower,
input[type="range"].progress-bar::-ms-fill-upper {
  background: transparent;
}

.time {
  color: grey;
}

.w-3{
  width: 3rem;
}

.w-8{
  width: 8rem;
}

.w-12{
  width: 12rem;
}

.w-50{
  width: 50%;
}

.w-75{
  width: 75%;
}

.w-90{
  width: 90%;
}

.w-100{
  width: 100%;
}
.pa-05{
  padding: 0.5rem;
}


.pa-1{
  padding: 1rem;
}

.fs-2{
  font-size: 2rem;
}

.fs-250{
  font-size: 2.5rem;
}

.miniplayer-spacer{
  height:6rem;
  background-color: transparent;
}
.navmenu-spacer{
height:4rem;
background-color: transparent;
}


.header-footer {
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0.5rem;
}



/* Navbar Toggle */
.navbar-light .navbar-toggler {
  border: none;
  background: none;
  outline: none;
  box-shadow: none; /* Entfernt den Schatten */
}


/* Dropdown Icons Right-aligned */
.navbar-nav .nav-link {
  display: flex;
  align-items: center;
}

.navbar-nav .nav-link .icon {
  margin-left: 1rem; /* Abstand zwischen Icon und Text */
}

.navbar-nav .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
}

.navbar-nav .dropdown-menu .dropdown-item .icon {
  margin-left: auto; /* Schiebt das Icon nach rechts */
}

/* Vertikale Ausrichtung der Icons */
.navbar-nav .nav-link .icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem; /* Abstand zwischen Icon-Container und Text */
}


.popup-container {
  position: absolute;
  top: 5rem;  
  left: 50%;  
  transform: translateX(-50%); 
  background-color: transparent; 
  min-height: 10em;  
  width: 95%;    
  overflow-y: auto; 
  max-height: 80vh; 
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Vollständige Breite des Bildschirms */
  height: 800vh; /* Vollständige Höhe des Bildschirms */
  background-color: rgba(0, 0, 0, 0.5); /* Halbtransparenter schwarzer Hintergrund */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500; /* Hoch genug, um über anderen Elementen zu liegen */
  overflow: hidden; /* Verhindert das Scrollen des Inhalts unter dem Overlay */
}

.close-Button{
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: rgb(226, 226, 226); 
  border: none; 
  border-radius: 50%; 
  color: #0008a3; 
  width: 2.5rem; 
  height: 2.5rem; 
  display: flex;
  align-items: center;
  justify-content: center; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  cursor: pointer; 
}

.popup-content{
  background-color: rgb(226, 226, 226);
  width:100%;
  margin-top:1rem;
  padding:1rem;
  overflow-y: auto;
}
.long-text{
    color: #000000;
    font-weight: 100;
    font-size: 0.8rem;
    min-width: 3rem; /* Stellt sicher, dass die Zeitangaben nicht zusammendrücken */
    text-align: left;
}

.playlist-wrapper {
  overflow-y: auto; 
}

.list-group-item {
  cursor: pointer;  
  transition: background-color 0.3s ease;  
}

.list-group-item.active {
  color: white;    
  background-color: #007bff; 
  border-color: #007bff; }

.title-page{
  font-weight: 200;
  font-size: 1.5rem;
  color:#0008a3;
}

h3, .h3 {
  font-size: calc(1.1rem + .6vw);
  font-weight: 200;
}

h4, .h4 {
  font-size: calc(0.9rem + .6vw);
  font-weight: 100;
}

.content-right{
  display: flex;
  justify-content: flex-end;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.bor-25 {
  border-radius: 25%;
}

.bor-50 {
  border-radius: 50%;
}

.mr-1 {
  margin-right: 1rem;
}
.mr-150 {
  margin-right: 1.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-150 {
  margin-left: 1.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-150 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-150 {
  margin-bottom: 1.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-125 {
  padding-left: 1.25rem;
}

.pb-1 {
  padding-bottom: 1rem;
}


.ha-250 {
  height: 2.5rem;
}

.inline {
  display: inline;
}

.drag-handle {
  cursor: move;
  width: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 0.5rem;
}

.draggable-card {
  display: flex;
  flex-direction: row;
  align-items: center;
}



.map-container {
  height: calc(100vh - 20rem); 
  width: 100%;
  position: relative;
}

.map-container .leaflet-container {
  height: 100%;
  width: 100%;
}

.map-container .btn-locate {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 500;
}

.map-icon-container {
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.map-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-flex {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 12rem); 
  overflow: hidden; /* Verhindert das Scrollen des Containers */
}

.card-container {
  overflow-y: auto; /* Ermöglicht das Scrollen innerhalb der Card */
  
}


.track-details-wrapper {
  flex: 1 1 auto;
  overflow: auto; /* Ermöglicht das Scrollen bei Bedarf */
}

.controls-wrapper {
  position: absolute;
  bottom: 3.3rem;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.controls-pbig {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-coverimage {
  width: 100%;
}

.qr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500;
}

.qr-reader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1;
  margin-bottom:20rem;
}

.qr-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
}

.qr-video {
  width: 100%;
  height: auto;
}

.qr-controls {
  position: absolute;
  top: 5px;
  right: 5px;
}
.qr-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgb(226, 226, 226);
  border: none;
  border-radius: 50%;
  color: #0008a3;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  cursor: pointer;
}


.map-controls {
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 500; /* Ensure the buttons are above the map */
}

.map-control-button {
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 0.7rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.map-control-button:hover {
  background-color: #f0f0f0;
}

.quest-content {
  display: flex;
  flex-direction: column;
}

.quest-info {
  overflow: hidden; /* Verhindert Überlauf beim Umfließen */
}

.quest-illustration {
  float: left;
  width: 40%;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.quest-illustration img {
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer;
}

.quest-description {
  /* Kein spezielles Styling nötig, der Text fließt automatisch um das Bild */
}

.puzzle-list {
  clear: both; /* Verhindert, dass die Puzzleliste neben das Bild fließt */
  width: 100%;
}

.hover-icon-button:hover .text-primary {
  color: white !important;
}


.audio-controls-fixed {
  position: fixed;
  bottom: 7.3rem;
  left: 0;
  right: 0;

  padding: 1rem;
  z-index: 1000;
  display: flex;
  gap: 0.5rem;
  align-items: center;

}

/* Zusätzlicher Abstand für den Content, damit nichts verdeckt wird */
.content-with-fixed-controls {
  padding-bottom: 120px;
}

.flip-vertical {
  transform: scaleX(-1);
}