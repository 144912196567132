
  
  .qr-reader {
    position: absolute;
    top:10rem;
    width: 20rem;
    height: 20rem;
    padding: 20px;
    background-color: white; /* Hintergrundfarbe des Scanners */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Etwas Schatten für bessere Sichtbarkeit */
  }
  
  .qr-reader video {
    width: 100%;
    height: 17rem; /* Höhe automatisch anpassen */
    object-fit: cover;
    object-position: center;
  }
  
  .qr-reader .qr-box {
    position: absolute;
    width: 100%;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:yellow;
  }
  
  .qr-reader .qr-frame {
    max-width: 100%;
    display: block; /* Stellt sicher, dass es richtig skaliert */
  }
  